import config from 'config'
export default {
    name: 'ArchivesCreate',
    computed: {
        categoryItem() {
            let arr = this.categorys[this.categoryIndex] || {};
            return arr;
        },
        categorySubItems() {
            let items = this.categorys;
            let index = this.categoryIndex;
            return (items[index] && items[index].category_sub) || []
        },
        categoryThirdItems() {
            let index = this.categorySubIndex;
            return (this.categorySubItems[index] && this.categorySubItems[index].category_sub) || [];
        },

    },

    watch: {
        identity_number(val) {
            if (!/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(val)) return;
            let birthday = /\d{6}([12]\d{3})([01]\d)([0123]\d)\d{4}/.exec(val)
            if (birthday) {
                this.birthday = `${birthday[1]}-${birthday[2]}-${birthday[3]}`
            }
        },

    },

    mounted() {
        // this.uploadList = this.$refs.upload.fileList;
        // this.category_auth=_global.userInfo.category_auth;
        this.handleGetCategoryList(); // 获取项目类别列表
        this.handleStaffProperty(); // 获取劳务关系
        this.template(); //获取拓展字段
    },

    methods: {

        // 清空选择框
        clearSelect(e) {
            this.$refs[e].clearSingleSelect();
        },
        // 获取项目类别列表
        handleGetCategoryList() {
            if (this.loading) return;
            this.loading = true
            let options = {
                permission_type: 1
            };
            if (_global.userInfo.usertype == 1) options.category_tag = 'archive_entry';
            this.$api.archives.categoryList(options).then(res => {
                this.loading = false
                if (res.status_code != 0) return this.$Message.warning({
                    content: res.status_msg
                })
                this.categorys = res.list.map(v => {
                    v.category_sub.map(v => {
                        v.open = false;
                        v.add = false;
                        return v;
                    })
                    return v;
                })
                let archive_category_arr = [];
                if (this.roleStaff['archive/content/category/business_contract'] != 0) {
                    archive_category_arr.push('business_contract')
                }
                if (this.roleStaff['archive/content/category/employee_file'] != 0) {
                    archive_category_arr.push('employee_file')
                }
                if (this.roleStaff['archive/content/category/expatriates'] != 0) {
                    archive_category_arr.push('expatriates')
                }
                if (this.roleStaff['archive/content/category/partner'] != 0) {
                    archive_category_arr.push('partner')
                }
                this.categorys = this.categorys.filter(item => {
                    return archive_category_arr.includes(item.category_key)
                })
                this.handleGetDetail();
                if (this.resourceLabel.length == 0 && !this.$route.query.id) this.getMaterialList()
                if (this.extendOptionList.length == 0 && !this.$route.query.id) this.getExtendOptionList()

            }).catch(err => {
                this.loading = false
            })
        },

        // 获取详情
        handleGetDetail() {
            let {
                id,
                t
            } = this.$route.query;
            if (id == undefined) return
            this.archive_id = id;
            this.type = t;
            this.$api.archives.archivesInfo({
                archive_id: this.archive_id
            }).then(res => {
                this.loading = false
                if (res.status_code != 0) return this.$Message.warning({
                    content: res.status_msg
                })
                this.categorys.map((v, i) => {
                    if (v.category_id == res.obj.category_id) {
                        this.categoryIndex = i;
                        v.category_sub.map((val, index) => {
                            if (val.category_id == res.obj.sub_category_id) {
                                this.categorySubIndex = index;
                            }

                        })
                    }
                })
                if (this.resourceLabel.length == 0) this.getMaterialList()
                if (this.extendOptionList.length == 0) this.getExtendOptionList()
                // let material_list =res.obj.material_list.length>0?res.obj.material_list.slice(1,99999).split('|'):[];
                this.checkValue = [...res.obj.material_list];
                this.archives_name = res.obj.archive_name;
                // this.date = [res.obj.begin_date, res.obj.end_date];

                this.begin_date = res.obj.begin_date;
                this.end_date = res.obj.end_date;
                if (!this.begin_date) {
                    this.noFixedTerm=true
                }
                this.tmp_number = res.obj.tmp_number;

                this.first_party = res.obj.first_party;
                this.second_party = res.obj.second_party;
                this.third_party = res.obj.third_party;
                this.handler = res.obj.handler;
                this.memo = res.obj.memo;
                this.files = res.obj.files;
                this.categoryThirdIndex = res.obj.third_category_id;
                this.staff_property = Number(res.obj.staff_property);
                this.gander = res.obj.gander;
                this.identity_number = res.obj.identity_number;
                this.birthday = res.obj.birthday;
                this.archive_code = res.obj.archive_code;
                this.gander = Number(res.obj.gander)

                // categorySubIndex

                this.dingtalk_number = res.obj.dingtalk_number
                this.contract_number = res.obj.contract_number
                this.finance_number = res.obj.finance_number
                this.contract_amount = res.obj.contract_amount
                this.project_name = res.obj.project_name

                this.education = Number(res.obj.education)
                this.employing_unit = res.obj.employing_unit
                this.employer = res.obj.employer


                this.staff_type = Number(res.obj.staff_type)


            }).catch(err => {
                this.loading = false
                console.log(err)
            })

        },
        // 获取资料清单
        getMaterialList() {
            this.resourceLabel = [];
            this.$api.archives.getMaterialList({
                archive_type: this.categoryItem.category_key
            }).then(res => {
                this.resourceLabel = []
                for (var i in res.list) {
                    this.resourceLabel.push(res.list[i])
                }
            }).catch(err => {})
        },
        // 获取资料清单
        getExtendOptionList() {
            this.extendOptionList = [];
            this.$api.archives.getExtendOptionList({
                category_key: this.categoryItem.category_key,
                info_key: 'material_list'
            }).then(res => {
                this.extendOptionList = []
                for (var i in res.list) {
                    this.extendOptionList.push(res.list[i])
                }
            }).catch(err => {})
        },

        // 项目类别打开添加----去掉了
        handleOpen(id, index) {
            this.category_name = '';
            switch (index) {
                case 2:
                    this.categorySubItems.map(v => {
                        v.add = v.category_id == id;
                        return v;
                    })
                    break;
                case 3:
                    categoryThirdItems.map(v => {
                        v.add = v.category_id == id;
                        return v;
                    })
                    break;
            }
            // this.categorys = this.categorys.map(v => {
            //     v.add = v.category_id == id;
            //     return v;
            // })
            // break;
        },
        // 项目类别提示框消失时触发
        handlePoptip() {
            this.categorys.map(v => {
                v.open = false;
                v.add = false;
                return v;
            })
            this.category_name = '';
        },
        // 项目类别确定添加
        handleAddSubCategory(index) {
            let category_id = index == 2 ? this.categoryItem.category_id : this.categorySubItems[this.categorySubIndex].category_id;
            if (this.loading) return;
            this.loading = true
            this.$Message.loading({
                content: '请求中...',
                duration: 0
            })
            this.$api.archives.categoryAdd({
                name: this.category_name,
                parent_id: category_id
            }).then(res => {
                this.loading = false
                this.$Message.destroy()
                if (res.status_code != 0) return this.$Message.warning({
                    content: res.status_msg
                })
                this.parent_id = category_id;
                this.category_name = '';
                this.handleGetCategoryList();
            }).catch(err => {
                this.loading = false
                this.$Message.destroy()
                console.log(err)
            })
        },
        // 项目类别移除
        handleRemoveSubCategory(row) {
            if (this.loading) return;
            this.$Modal.confirm({
                title: '删除',
                content: `确定删除${row.category_name}`,
                loading: true,
                onOk: () => {
                    this.loading = true
                    this.$api.archives.categoryRemove({
                        category_id: row.category_id
                    }).then(res => {
                        this.loading = false
                        this.categoryThirdIndex = 0;
                        this.$Modal.remove()
                        if (res.status_code != 0) return this.$Message.warning({
                            content: res.status_msg
                        })
                        this.$Message.success({
                            content: '删除成功',
                            duration: 5000
                        })
                        this.$Message.destroy()
                        this.handleGetCategoryList();
                        // if(this.categoryThirdItems.length==1)this.categoryThirdItems=[];
                        // if(this.categorySubItems.length==1)this.categorySubItems=[];
                    }).catch(err => {
                        this.loading = false
                        this.$Modal.remove()
                        this.$Message.destroy()
                        console.log(err)
                    })
                }
            })
        },
        // 获取劳务关系
        template() {
            this.$api.archives.template({
                archive_type: 'employee_file'
            }).then(res => {
                if (res.status_code != 0) return this.$Message.warning({
                    content: res.status_msg
                })
                this.staff_type_list = res.obj.staff_type;
                this.education_list = res.obj.education;
                this.staff_property_list = res.obj.staff_property;

            }).catch(err => {
                console.log(err)
            })
        },

        // 获取劳务关系
        handleStaffProperty() {
            this.$api.archives.staffProperty().then(res => {
                if (res.status_code != 0) return this.$Message.warning({
                    content: res.status_msg
                })
                this.staff_property_items = res.list;
            }).catch(err => {
                console.log(err)
            })
        },

        // 上传文件前
        beforeUpload(files) {
            this.isDoing = true;

            if (this.folderList.length == 0) {
                this.$Message.warning({
                    content: '请先创建目录..'
                })
                return false;
            }
            if ((files.size / (1024 * 1024)).toFixed(2) > 50) {
                return this.$Message.warning({
                    content: '文件大小不能超过50MB'
                })
            }
            // lastModified: 1627019715142
            // lastModifiedDate: Fri Jul 23 2021 13:55:15 GMT+0800 (GMT+08:00) {}
            // name: "商社保开发清单.xlsx"
            // size: 20699
            // type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            // uid: 1627023211687
            // webkitRelativePath: ""
            this.files_current_obj.push({
                download_url: "",
                file_name: files.name,
                file_path: "",
                file_tag: "",
                file_url: "",
                tag_id: 0,
                size: (files.size / (1024 * 1024)).toFixed(2) + "MB"
            })

        },


        // 确认提交档案（添加、修改）
        handleSubmit() {
            if (this.loading) return;
            // 校验
            let msg = this.handleCheck();
            if (msg) return this.$Message.warning({
                content: msg
            });
            // 基本数据设置
            let obj = {
                // category_id: this.categoryItem.category_key=='partner'?this.categoryThirdIndex:this.categoryItem.category_id,
                category_id: this.categoryThirdIndex,
                archives_name: this.archives_name,
                begin_date: this.begin_date,
                end_date: this.end_date,
                tmp_number: this.tmp_number,
                archive_id: this.archive_id == null ? '' : this.archive_id,
                first_party: this.first_party,
                second_party: this.second_party,
                third_party: this.third_party,
                handler: this.userInfo.realname,
                memo: this.memo,
                project_name: this.project_name,
                dingtalk_number: this.dingtalk_number,
                contract_number: this.contract_number,
                finance_number: this.finance_number,
                contract_amount: this.contract_amount,

                employing_unit: this.employing_unit,
                employer: this.employer,
                education: this.education,
                staff_type: this.staff_type,
                // staff_property:this.staff_property,

                material_list: this.checkValue,
                delete_files: this.delete_files,
                staff_property: Number(this.staff_property),
                staff_property_items: this.staff_property_items,
                name: this.name,
                gander: this.gander,
                identity_number: this.identity_number,
                birthday: this.birthday && this.birthday.replace(/-/g, ''),
                files: this.files_current_obj.map(v => {
                    return {
                        file_name: v.file_name,
                        file_path: v.file_path,
                        file_tag: v.tag_id == 0 ? '' : v.file_tag
                    }
                }),
                delete_files: this.delete_files
            };

            // 根据项目类别筛选数据
            let options = {};

            this[this.categoryItem.category_key].map(val => {
                options[val] = obj[val]
            })

            this.$Modal.confirm({
                title: !this.archive_id ? '档案添加' : '档案修改',
                content: '确定保存档案内容？',
                loading: true,
                onOk: () => {
                    this.$Message.loading({
                        content: '数据提交中...',
                        duration: 0
                    })
                    this.loading = true;
                    this.$api.archives.archivesModify(options).then(res => {
                        this.loading = false
                        this.$Message.destroy()
                        this.$Modal.remove()
                        if (res.status_code != 0) return this.$Message.warning({
                            content: res.status_msg
                        })
                        this.$Message.success({
                            content: !this.archive_id ? '档案录入成功' : '档案修改成功'
                        })
                        this.addSuccessModal = true;
                        this.newArchiveObj = {
                            type: this.categoryItem.category_key,
                            code: res.obj.archive_code
                        }
                    }).catch(err => {
                        this.loading = false
                        this.$Message.destroy()
                        this.$Modal.remove()
                        this.$Message.warning({
                            content: !this.archive_id ? '档案录入失败' : '档案修改失败'
                        })
                        console.log(err)
                    })
                }
            })
        },
        toDetail() {
            this.$router.push({
                name: 'RetrievalDetail',
                query: this.newArchiveObj
            })
        },
        handleCheck() {
            let msg = '';
            if (this.checkValue.length <= 0) msg = '资料清单不能为空'
            // 内部员工
            if (this.categoryItem.category_key == 'employee_file' || this.categoryItem.category_key == 'expatriates') {
                if (!this.categoryThirdIndex) msg = '请选择分类ID'
                if (this.birthday == '') msg = '请输入员工生日'
                if (!/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(this.identity_number)) msg = '身份证格式错误'

                if (this.archives_name == '') msg = '请输入员工姓名'
                if (this.staff_property == '') msg = '请选择员工状态'
                // if(!this.date[0])msg = '请选择合同期限'
                if (this.education == '') msg = '请选择学历'
                if (this.staff_type == '') msg = '请选择员工类型'
                if (this.employing_unit == '') msg = '请输入用工单位'


                if (!this.noFixedTerm && this.begin_date == '') {
                    msg = '请输入合同开始时间';
                }
                if (!this.noFixedTerm && this.end_date == '') {
                    msg = '请输入合同结束时间';
                }

                if (this.categoryItem.category_key == 'expatriates') {
                    if (this.employer == '') msg = '请输入用人单位'
                }



                // 其他
            } else if (this.categoryItem.category_key == 'partner') {
                if (this.archives_name == '') msg = '请输入文件名'
                if (this.finance_number == '') msg = '请输入财务编号'
                if (!this.categoryThirdIndex) msg = '请选择分类ID'

            }
            // 商务协议
            else if (this.categoryItem.category_key == 'business_contract') {
                // project_name:'',   //项目名称
                //- dingtalk_number:'', //审批编号
                //- contract_number:'', //合同编号
                //- contract_amount:'', //合同金额
                // if(!this.date[0])msg = '请选择立项日期'
                if (this.begin_date == '') msg = '请选择立项开始时间'
                if (this.end_date == '') msg = '请选择立项结束时间'
                // if (this.date.length <= 0) msg = '请选择立项日期'
                if (!this.categoryThirdIndex) msg = '请选择分类ID'
                if (this.archives_name == '') msg = '请输入合同名称'
                if (this.finance_number == '') msg = '请输入财务编号'
                if (this.first_party == '') msg = '请输入合同甲方'
                if (this.second_party == '') msg = '请输入合同乙方'
                if (this.third_party == '') msg = '请输入合同丙方'
                if (this.project_name == '') msg = '请输入项目名称'
                if (this.dingtalk_number == '') msg = '请输入审批编号'
                if (this.contract_number == '') msg = '请输入合同编号'
                // if (this.contract_amount == '') msg = '请输入合同金额'
                // if (this.handler == '') msg = '请输入经手人'

            }

            return msg;
        },
        dateCom1(e) {
            this.begin_date = e;
        },
        dateCom2(e) {
            this.end_date = e;
        },
        // 选择资料清单回调
        handdleCheckResource(val, i) {

        },
        // 查看上传附件
        handleFileList(page) {
            let {
                id,
                t
            } = this.$route.query;
            this.folderCurrent = 0;
            if (page == 0) {
                this.isEdit = false;
                this.isFiles = true;
                if (!id) return this.isEdit = false;
            } else {
                this.isEdit = true;
            }
            if (!id && this.files_current_obj.length > 0) return this.isFiles = true;
            if (!id && this.files_current_obj.length <= 0) return this.$Message.warning({
                content: '暂无附件数据'
            })
            if (this.loading) return;
            this.$Message.loading({
                content: '数据请求中...',
                duration: 0
            })
            this.loading = true;
            this.$api.archives.fileList({
                archive_id: this.archive_id,
                page_number: 20,
                page: page
            }).then(res => {
                this.loading = false
                this.$Message.destroy()
                if (res.status_code != 0) return this.$Message.warning({
                    content: res.status_msg
                })
                this.items = res.list;


                this.obj = res.obj;
                this.getFolderList()
                this.isFiles = true;
            }).catch(err => {
                this.loading = false
                this.$Message.destroy()
                console.log(err)
            })
        },
        // 获取标签列表
        getFolderList() {
            if (this.folderList.length > 1) return
            this.$api.archives.getFolderList({
                archive_id: this.archive_id
            }).then(res => {
                let arr = res.list;
                arr.forEach((v, i) => {
                    arr[i].file_num = 0;
                    this.items.forEach(e => {
                        if (v.tag_id == e.tag_id) {
                            arr[i].file_num += 1
                        }
                    })
                })
                this.folderList = this.folderList.concat(arr)
            })
        },
        // 相关附件操作
        handleFilesAction(row, type, arr = 0, index = 0) {
            switch (type) {
                case 'view':
                    window.open(row.file_url)
                    break;
                case 'download':
                    window.open(row.download_url)
                    break;
                case 'delete':
                    if (this.loading) return;
                    this.$Modal.confirm({
                        title: '删除',
                        content: `删除附件${row.file_name}？`,
                        onOk: () => {
                            // 未上传文件
                            if (arr == 0) {
                                this.folderList[this.folderCurrent].file_num--
                                this.files_current_obj.splice(index, 1)
                            }
                            // 已上传文件
                            if (arr == 1) {
                                this.loading = true;
                                this.$Message.loading({
                                    content: '删除中...',
                                    duration: 0
                                })
                                this.$api.archives.fileDel({
                                    archive_id: Number(this.archive_id),
                                    delete_files: [Number(row.file_id)]
                                }).then(res => {
                                    this.loading = false
                                    this.$Message.destroy()
                                    this.folderList[this.folderCurrent].file_num--
                                    if (res.status_code != 0) return this.$Message.warning({
                                        content: res.status_msg
                                    })
                                    this.$Message.success({
                                        content: '删除成功'
                                    })
                                    this.items.splice(index, 1)
                                    this.files--
                                }).catch(err => {
                                    this.loading = false
                                    this.$Message.destroy()
                                    this.$Message.success({
                                        content: '删除失败'
                                    })
                                    console.log(err)
                                })

                            }
                        }
                    })
                    break;
            }
        },
        handleType(row) {
            let arr = row.file_name.split('.');
            if (['jpg', 'png', 'jpeg', 'gif'].indexOf(arr[arr.length - 1]) >= 0) return 'images'
            if (['rar', 'zip', 'cab', 'lzh', 'ace', '7-zip', 'tar', 'gzip', 'uue', 'bz2', 'jar', 'iso'].indexOf(arr[arr.length - 1]) >= 0) return 'zip'
            return 'file'
        },


        // 查看调档记录
        handleWatchHistory(page) {
            if (this.loading) return;
            this.$Message.loading({
                content: '数据请求中...',
                duration: 0
            })
            this.loading = true;
            this.$api.archives.retrieveLogList({
                archive_id: this.archive_id,
                page_number: 20,
                page: page
            }).then(res => {
                this.loading = false
                this.$Message.destroy()
                if (res.status_code != 0) return this.$Message.warning({
                    content: res.status_msg
                })

            }).catch(err => {
                this.loading = false
                this.$Message.destroy()
                console.log(err)
            })
        },

        // 上传 - 成功
        handleOnSuccess(response, file, fileList) {
            let obj = response.list[0];
            this.folderList[this.folderCurrent].file_num++
            obj['file_tag'] = this.folderList[this.folderCurrent].tag_name;
            obj['tag_id'] = this.folderList[this.folderCurrent].tag_id;
            obj.size = (file.size / (1024 * 1024)).toFixed(2) + "MB"
            this.$Message.success({
                content: obj.file_name + '上传成功'
            })
            this.files_current_obj.pop();
            this.files_current_obj.push(obj)
            this.uploadList = [];
            this.isDoing = false
            this.$refs.upload.fileList = [];

        },
        // 上传 - 失败
        handleOnError(response, file, fileList) {
            let obj = response.list[0];
            this.$Message.success({
                content: obj.file_name + '上传失败'
            })
        },
        // 上传进度
        handleProgress(event, file, fileList) {
            this.uploadList = this.$refs.upload.fileList;

            // 调用监听 上传进度 的事件
            // file.percentage=0;
            event.target.onprogress = function (e) {
                let uploadPercent = parseFloat(((e.loaded / e.total) * 100).toFixed(2)) // 保留两位小数，具体根据自己需求做更改
                this.percent = uploadPercent;
                file.showProgress = true
                file.percentage = uploadPercent
                // 手动设置显示上传进度条 以及上传百分比

            }
        },

        // 切换目录
        changeFolder(index, v) {
            this.folderCurrent = index;
        },
        // 删除标签接口
        handdleDeleteFolder(i, val) {
            let {
                id,
                t
            } = this.$route.query;
            if (this.loading) return
            this.$Modal.confirm({
                title: '删除',
                content: `将清空${val.tag_name}里面的所有文件，且不可恢复，确定？`,
                onOk: () => {
                    this.loading = true;
                    if (!id) { //新增的标签
                        for (let index = 0, len = this.files_current_obj.length; index < len; index++) {
                            const e = this.files_current_obj[index];
                            if (e.tag_id == val.tag_id) {
                                this.files_current_obj.splice(index, 1)
                                index--;
                                len--;
                            }
                        }
                        this.folderCurrent = this.folderCurrent > 0 ? this.folderCurrent - 1 : 0;
                        this.folderList.splice(i, 1);
                        this.loading = false;
                        return
                    }
                    let data = {
                        archive_id: this.archive_id,
                        delete_tags: [val.tag_id]
                    };
                    this.$api.archives.deleteFolder(data).then(res => {
                        this.loading = false;
                        this.$Message.destroy()
                        this.$Message.success({
                            content: '删除成功'
                        })
                        this.folderCurrent = this.folderCurrent > 0 ? this.folderCurrent - 1 : 0;
                        this.folderList.splice(i, 1);
                        this.handleFileList();
                    }).catch(err => {
                        this.loading = false;
                        this.$Message.destroy()
                        this.$Message.success({
                            content: '删除失败'
                        })
                    })
                }
            })
        },
        // 添加目录
        addFolder() {
            if (!this.folderName) return this.$Message.warning({
                content: '文件夹名称不能为空...'
            })
            if (this.folderList.map(v => v.tag_name).indexOf(this.folderName) >= 0) return this.$Message.warning({
                content: '文件夹已存在...'
            }); // 排除重名
            this.folderList.push({
                tag_name: this.folderName,
                tag_id: new Date().getTime(),
                file_num: 0
            })
            this.folderName = '';
            this.folderCurrent = this.folderList.length - 1;
        },
        // 添加选项
        handleAddOption() {
            this.$Message.loading({
                content: '加载中...',
                duration: 0
            })
            this.$api.archives.addExtentOption({
                category_key: this.categoryItem.category_key,
                info_key: 'material_list',
                option_name: this.optionValue
            }).then(res => {
                this.$Message.destroy()
                this.addOptionModal = false;
                if (res.status_code != 0) return this.$Message.warning({
                    content: res.status_msg
                })
                this.$Message.success({
                    content: '添加成功'
                })
                this.optionValue = '';
                this.getExtendOptionList();

            }).catch(err => {
                this.$Message.destroy()
                this.addOptionModal = false;
                console.log(err)
            })

        },
        // 删除选项
        delExtendOption(item) {
            if (this.checkValue.includes(Number(item.option_id))) return this.$Message.warning({content:'请取消选中该选项后再进行删除'})
            this.$Modal.confirm({
                title: `是否删除"${item.option_name}"选项`,
                onOk: () => {
                    this.$Message.loading({
                        content: '删除中...',
                        duration: 0
                    })
                    this.$api.archives.extendOptionDel({
                        handle_id: item.handle_id,
                        force: 0,
                    }).then(res => {
                        this.$Message.destroy()
                        if (res.status_code == 101) {
                            setTimeout(() => {
                                this.$Modal.confirm({
                                    title: '提示',
                                    content: '该选项已关联档案，是否删除？删除后，关联档案数据还是会保留，且可在【检索及报表】中查询该选项数据。',
                                    onOk: () => {
                                        this.$Message.loading({
                                            content: '删除中...',
                                            duration: 0
                                        })
                                        this.$api.archives.extendOptionDel({
                                            handle_id: item.handle_id,
                                            force: 1,
                                        }).then(res => {
                                            this.$Message.destroy()
                                            if (res.status_code != 0) return this.$Message.warning({
                                                content: res.status_msg
                                            })
                                            this.$Message.success({
                                                content: '删除成功'
                                            })
                                            this.getExtendOptionList();

                                        }).catch(err => {
                                            this.$Message.destroy()
                                            console.log(err)
                                        })
                                    }
                                })

                            },300)
                            return
                        }
                        if (res.status_code != 0) return this.$Message.warning({
                            content: res.status_msg
                        })
                        this.$Message.success({
                            content: '删除成功'
                        })
                        this.getExtendOptionList();

                    }).catch(err => {
                        this.$Message.destroy()
                        console.log(err)
                    })

                 }
            })
        },
        setTerm(bool) {
            if (bool) {
                this.begin_date = '';
                this.end_date = '';
            }

        },


        // 重置表单
        resetForm() {
            if (this.$route.query.id) {
                // 修改档案
                this.editClear()
                return;
                this.files_current_obj = [];
                this.items = [];
                this.folderList = [{
                    tag_name: '全部',
                    tag_id: 0
                }];
                this.folderCurrent = 0;
                this.handleGetDetail();
                if (this.resourceLabel.length == 0 && !this.$route.query.id) this.getMaterialList();
                return
            }
            // 新建的档案，可以直接清空

            this.clearData()
        },
        // 编辑--初始化数据
        editClear() {
            this.project_name = '', //项目名称
                this.dingtalk_number = '', //审批编号
                this.contract_number = '', //合同编号
                this.finance_number = '', //财务编号
                this.contract_amount = '', //合同金额
                this.education = '', //学历
                this.staff_type = '', //员工类型
                this.employing_unit = '', //用工单位
                this.employer = '', //用人单位

                this.archives_name = ''; // 档案名称/员工姓名/公司名称
            this.date = []; // 开始、结束时间

            this.end_date = '',
                this.begin_date = '',
                this.tmp_number = '',

                // this.archive_id= '1';// 档案id
                this.first_party = ''; // 甲方
            this.second_party = ''; // 乙方
            this.third_party = ''; // 丙方
            this.handler = ''; // 经手人
            this.memo = ''; // 备注
            this.files = 0; // 附件数量
            this.delete_files = []; // 需要删除的附件id数组
            this.files_current_obj = []; // 当前上传文件
            this.staff_property = 0; // 劳动关系
            this.gander = 1; // 性别：1-男，0-女
            this.identity_number = ''; // 身份证
            this.birthday = ''; // 生日
            this.archive_code = ''; // 档案编号
            this.checkValue = []; //资料清单选中项
            this.archive_name = ''; //公司名称
            this.files = [];
            this.folderList = [{
                tag_name: '全部',
                tag_id: 0
            }]; //目录列表
            this.folderCurrent = 0; //当前目录
            this.folderName = '';
            this.delete_files = [];
            // this.categorySubIndex= 0;
            // this.categoryThirdIndex = '';

        },

        // 初始化数据
        clearData() {
            this.project_name = '', //项目名称
                this.dingtalk_number = '', //审批编号
                this.contract_number = '', //合同编号
                this.finance_number = '', //财务编号
                this.contract_amount = '', //合同金额
                this.education = '', //学历
                this.staff_type = '', //员工类型
                this.employing_unit = '', //用工单位
                this.employer = '', //用人单位

                this.archives_name = ''; // 档案名称/员工姓名/公司名称
            this.date = []; // 开始、结束时间
            this.noFixedTerm = false;

            this.end_date = '',
                this.begin_date = '',
                this.tmp_number = '',

                this.archive_id = ''; // 档案id
            this.first_party = ''; // 甲方
            this.second_party = ''; // 乙方
            this.third_party = ''; // 丙方
            this.handler = ''; // 经手人
            this.memo = ''; // 备注
            this.files = 0; // 附件数量
            this.delete_files = []; // 需要删除的附件id数组
            this.files_current_obj = []; // 当前上传文件
            this.staff_property = 0; // 劳动关系
            this.gander = 1; // 性别：1-男，0-女
            this.identity_number = ''; // 身份证
            this.birthday = ''; // 生日
            this.archive_code = ''; // 档案编号
            this.checkValue = []; //资料清单选中项
            this.archive_name = ''; //公司名称
            this.files = [];
            this.folderList = [{
                tag_name: '全部',
                tag_id: 0
            }]; //目录列表
            this.folderCurrent = 0; //当前目录
            this.folderName = '';
            this.delete_files = [];
            if (this.categorySubItems.length > 0) {
                this.categorySubIndex = 0;
            } else {
                this.categorySubIndex = '';
            }
            this.categoryThirdIndex = '';
            this.$router.push({
                path: '/create'
            })
        }
    },

    data() {
        return {
            isDoing: false,
            percent: 0,
            uploadList: [],
            category_auth: 0,
            uploadData: {
                token: _global.token,
            },
            userInfo: _global.userInfo,
            publicPath: process.env.BASE_URL,
            config: config,
            loading: false,
            isLoading: false,
            keyword: '',
            id: null,
            type: null,

            // 内部员工
            employing_unit: '',
            employer: '',
            staff_property_list: [], //员工状态
            property: '',
            staff_type_list: [],
            staff_type: '',
            education_list: [],
            education: '',
            // 项目类别
            category_name: '',
            categoryIndex: 0,
            categorySubIndex: 0,
            categoryThirdIndex: '',
            categorys: [],
            parent_id: 0,
            categoryAddFlag: false,
            // 调档记录
            page: 1,

            // 商务协议
            project_name: '', //项目名称
            dingtalk_number: '', //审批编号
            contract_number: '', //合同编号
            finance_number: '', //财务编号
            contract_amount: '', //合同金额

            begin_date: '',
            end_date: '',
            tmp_number: '', //编号


            archives_name: '', // 档案名称/员工姓名/公司名称
            date: [], // 开始、结束时间
            archive_id: '', // 档案id
            first_party: '', // 甲方
            second_party: '', // 乙方
            third_party: '', // 丙方
            handler: '', // 经手人
            memo: '', // 备注
            files: 0, // 附件数量
            delete_files: [], // 需要删除的附件id数组
            files_current_obj: [], // 当前上传文件
            folderList: [{
                tag_name: '全部',
                tag_id: 0
            }], //上传的目录文件夹
            staff_property: 0, // 劳动关系
            staff_property_items: [], // 劳务关系数组
            gander: 1, // 性别：1-男，0-女
            identity_number: '', // 身份证
            birthday: '', // 生日
            archive_code: '', // 档案编号
            resourceLabel: [], //资料清单列表
            checkValue: [], //资料清单选中项
            archive_name: '', //公司名称
            files: [],
            folderCurrent: 0, //当前目录
            folderName: '',
            delete_files: [],
            isEdit: false, //是否是编辑资料
            employee_file: ['category_id', 'material_list', 'tmp_number', 'begin_date', 'end_date', 'staff_property', 'archives_name',
                'gander', 'archive_id', 'identity_number', 'education', 'staff_type', "employing_unit",
                'birthday', 'files', 'delete_files', 'memo'
            ], //内部员工
            business_contract: ['category_id', 'project_name', 'dingtalk_number', 'tmp_number', 'contract_number', 'finance_number', 'contract_amount', 'material_list', 'begin_date', 'end_date', 'archives_name', 'archive_id', 'first_party', 'second_party', 'third_party', 'handler', 'files', 'delete_files', 'memo'],
            partner: ['category_id', 'archives_name', 'archive_id', 'tmp_number','finance_number','material_list', 'files', 'memo'], //其他
            expatriates: ['category_id', 'material_list', 'begin_date', 'end_date', 'tmp_number', 'staff_property', 'archives_name',
                'gander', 'archive_id', 'identity_number', 'education', 'staff_type', 'employing_unit', 'employer',
                'birthday', 'files', 'delete_files', 'memo'
            ], //外派员工
            noFixedTerm:false,//无固定期限
            addOptionModal: false,
            optionValue: '',
            extendOptionList: [],

            isFiles: false,
            items: [],
            obj: {},
            addSuccessModal: false, //添加成功的弹窗
            newArchiveObj: {}, //存储新录入的档案id
            roleStaff: JSON.parse(localStorage.getItem('staff_auth_info')),
        }
    }
}